import React from "react";
import useAxios from "axios-hooks";
import CloseIcon from "@mui/icons-material/Close";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Container from "@mui/material/Container";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { API_URL } from "./Config";

export default function ImageView(props) {
  const { currentCamera, year, month } = props;
  const { camera_id } = currentCamera;
  const [open, setOpen] = React.useState(false);
  const [openIndex, setOpenIndex] = React.useState(null);

  const [{ data, loading, error }] = useAxios(
    `${API_URL}/camera/${camera_id}/year/${year}/month/${month}/images`
  );
  if (loading) return <CircularProgress />;
  if (error)
    return (
      <Alert severity="error">
        Virhe! Kuvia ei saatu. Syy: {error.message}
      </Alert>
    );

  const options = {
    weekday: "short",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: currentCamera.timezone
  };

  const dateTimeFormat = new Intl.DateTimeFormat("fi-FI", options);

  const handleOpen = (index) => {
    setOpenIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLeft = () => {
    if (openIndex === 0) {
      setOpenIndex(data.length - 1);
    } else {
      setOpenIndex(openIndex - 1);
    }
  };

  const handleRight = () => {
    if (openIndex === data.length - 1) {
      setOpenIndex(0);
    } else {
      setOpenIndex(openIndex + 1);
    }
  };

  const openedItem = open ? data[openIndex] : null;
  const openedItemDateTime = open
    ? dateTimeFormat.format(new Date(openedItem.creation_time))
    : null;
  return (
    <>
      <Container>
        <ImageList cols={3}>
          {data.map((item, index) => {
            const { creation_time } = item;
            const ctDate = new Date(creation_time);
            const formattedDate = dateTimeFormat.format(ctDate);
            return (
              <ImageListItem key={item.url} onClick={() => handleOpen(index)}>
                <img
                  style={{ objectFit: "cover", width: "100%", height: "100%" }}
                  src={item.thumbnail_url || item.url}
                  alt={formattedDate}
                  loading="lazy"
                />
                <ImageListItemBar title={formattedDate} />
              </ImageListItem>
            );
          })}
        </ImageList>
      </Container>
      {open ? (
        <Modal
          open={open}
          onClose={handleClose}
          closeAfterTransition
          sx={{
            position: "fixed"
          }}
        >
          <Fade in={open}>
            <div
              style={{
                position: "absolute",
                top: "10%",
                left: "10%",
                width: "80%",
                height: "80%"
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                  aspectRatio: 1.7777777,
                  width: "100%",
                  height: "100%"
                }}
                onClick={handleClose}
                src={openedItem.url}
                alt={openedItemDateTime}
              />
              <ImageListItemBar title={openedItemDateTime} />
              <IconButton onClick={handleLeft} aria-label="left">
                <KeyboardArrowLeftIcon style={{ fontSize: 50 }} />
              </IconButton>
              <IconButton onClick={handleRight} aria-label="right">
                <KeyboardArrowRightIcon style={{ fontSize: 50 }} />
              </IconButton>
              <IconButton onClick={handleClose} aria-label="close">
                <CloseIcon fontSize="large" />
              </IconButton>
            </div>
          </Fade>
        </Modal>
      ) : null}
    </>
  );
}
