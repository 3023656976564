import React from "react";
import useAxios from "axios-hooks";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MuiDrawer from "@mui/material/Drawer";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import StreamIcon from "@mui/icons-material/Stream";
import ImageView from "./ImageView";
import VREPlayer from "videojs-react-enhanced";
import "video.js/dist/video-js.css";
import { API_URL, DRAWER_WIDTH, MONTH_NAMES } from "./Config";

const openedMixin = (theme) => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`
  }
});

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `-${DRAWER_WIDTH}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    })
  })
);

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

function MonthList(props) {
  const { currentCamera, year, currentMonth, setCurrentMonth } = props;
  const { camera_id } = currentCamera;
  const [currentMonthIndex, setMonthIndex] = React.useState(0);

  const [{ data, loading, error }] = useAxios(
    `${API_URL}/camera/${camera_id}/year/${year}/months`
  );

  if (loading) return <CircularProgress />;
  if (error)
    return (
      <Alert severity="error">
        Virhe! Kuukausia ei saatu. Syy: {error.message}
      </Alert>
    );

  if (!currentMonth) {
    setCurrentMonth(data[currentMonthIndex]);
  }

  const handleMonthClick = (_, index) => {
    setMonthIndex(index);
    setCurrentMonth(data[index]);
  };

  return data.map((month, index) => (
    <ListItem
      button
      selected={currentMonthIndex === index}
      key={`c${camera_id}y${year}m${month}`}
      onClick={(event) => handleMonthClick(event, index)}
    >
      <ListItemText inset secondary={MONTH_NAMES[month]} />
    </ListItem>
  ));
}

export default function CameraView(props) {
  const { currentCamera } = props;
  const { camera_id, stream_uri } = currentCamera;
  const [currentYearIndex, setYearIndex] = React.useState(-1);
  const [currentMonth, setCurrentMonth] = React.useState(null);
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  // const [previousCameraId, setPreviousCameraId] = React.useState(1);

  const [{ data, loading, error }] = useAxios(
    `${API_URL}/camera/${camera_id}/years`
  );

  if (loading) return <CircularProgress />;
  if (error)
    return (
      <Alert severity="error">
        Virhe! Kameratietoja ei saatu. Syy: {error.message}
      </Alert>
    );

  const handleYearClick = (_, index) => {
    setYearIndex(index);
    setCurrentMonth(null);
  };
  // ffmpeg -i rtmp://admin:koptsi666@tapiolakam.ddns.net:554/ -vcodec libx264 -vprofile baseline -acodec aac -strict -2 -f flv rtmp://localhost/tapiola/kamera1

  console.log("currentYearIndex", currentYearIndex);
  console.log("currentCamera", currentCamera);

  const playerOptions = {
    src: currentCamera.stream_uri,
    controls: true,
    autoplay: "play",
    muted: "true",
    preload: "none",
    playsinline: true
  };

  const videojsOptions = {
    fluid: true,
    language: "fi",
    nativeControlsForTouch: true
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Box component="nav" aria-label="Camera Navigation">
        <Drawer
          sx={{
            "& .MuiDrawer-paper": {
              position: "relative"
            }
          }}
          variant="permanent"
          anchor="left"
          open
        >
          <List>
            {stream_uri && (
              <React.Fragment key={`c${camera_id}live`}>
                <ListItem
                  button
                  selected={currentYearIndex === -1}

                  onClick={(event) => handleYearClick(event, -1)}
                >
                  <ListItemIcon>
                    <StreamIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Livekuva"} />
                </ListItem>
                <Divider />
              </React.Fragment>
            )}
            <Divider />
            {data.map((year, index) => (
              <React.Fragment key={`c${camera_id}>_${year}`}>
                <ListItem
                  button
                  selected={currentYearIndex === index}
                  key={`c${camera_id}y${year}`}
                  onClick={(event) => handleYearClick(event, index)}
                >
                  <ListItemIcon>
                    <PhotoLibraryIcon />
                  </ListItemIcon>
                  <ListItemText primary={year} />
                </ListItem>
                {currentYearIndex === index ? (
                  <MonthList
                    currentCamera={currentCamera}
                    year={data[currentYearIndex]}
                    currentMonth={currentMonth}
                    setCurrentMonth={setCurrentMonth}
                  />
                ) : null}
                </React.Fragment>
            ))}
          </List>
        </Drawer>
      </Box>
      <Main open={open}>
        {currentYearIndex === -1 ? (
          <VREPlayer
            playerOptions={playerOptions}
            videojsOptions={videojsOptions}
            hideList={[
              "volumePanel",
              "pictureInPictureToggle",
              "progressControl",
              "remainingTimeDisplay"
            ]}
            onReady={(player) => console.log(player)}
            // onPlay={(e, _, second) => console.log("Play!")}
            // onPause={(e, _, second) => console.log("Pause!")}
            // onEnded={(e, _) => console.log("Ended!")}
          />
        ) : null}
        {currentYearIndex >= 0 && currentMonth ? (
          <ImageView
            currentCamera={currentCamera}
            year={data[currentYearIndex]}
            month={currentMonth}
          />
        ) : null}
      </Main>
    </Box>
  );
};
