import React from "react";
import useAxios from "axios-hooks";
import { styled } from "@mui/material/styles";
import GlobalStyles from "@mui/material/GlobalStyles";
import CssBaseline from "@mui/material/CssBaseline";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import MuiAppBar from "@mui/material/AppBar";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Alert from "@mui/material/Alert";
import Tab from "@mui/material/Tab";
import Toolbar from "@mui/material/Toolbar";
import CameraView from "./CameraView";
import { API_URL, DRAWER_WIDTH } from "./Config";
import logo from "./images/espoo-120x80.png";
import { theme, useStyles } from "./Theme";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: `${DRAWER_WIDTH}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function Tapiolakamera() {
  const [currentTab, setCurrentTab] = React.useState(0);
  const classes = useStyles();

  const [{ data, loading, error }] = useAxios(`${API_URL}/cameras`);

  if (loading) return <CircularProgress />;
  if (error)
    return (
      <Alert severity="error">
        Virhe! Kameratietoja ei saatu. Syy: {error.message}
      </Alert>
    );

  const handleChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const tabs = data.map((c) => (
    <Tab key={`ct_${c.camera_id}`} label={c.name} {...a11yProps(c.camera_id)} />
  ));
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <AppBar position="static" enableColorOnDark elevation={0}>
        <Toolbar sx={{ pr: "24px" }}>
          <img alt="Espoo" src={logo} className={classes.logo} />
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              aria-label="Kamerat"
              indicatorColor="secondary"
              textColor="inherit"
            >
              {tabs}
            </Tabs>
          </Box>
        </Toolbar>
      </AppBar>
      <CameraView key={`cv${currentTab}`} currentCamera={data[currentTab]} />
    </ThemeProvider>
  );
}
